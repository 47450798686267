/** @format */

import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";
import {
    StyledHeroSection,
    StyledHeroImage,
    StyledHeaderText,
    StyledAnimationWrapper,
} from "./StyledHeroSection";

const HeroSection = () => {
    const data = useStaticQuery(graphql`
        query StronaGlownaQuery {
            datoCmsStronaGlowna {
                animowanyTekstLewo
                animowanyTekstPrawo1
                animowanyTekstPrawo2
                animowanyTekstPrawo3
                animowanyTekstPrawo4
                tlo {
                    gatsbyImageData(placeholder: TRACED_SVG)
                }
            }
            datoCmsNaglowek {
                drugaLinia
                pierwszaLinia
            }
        }
    `);

    const [text, setText] = useState("");
    const [index, setIndex] = useState(0);
    const image = getImage(data.datoCmsStronaGlowna.tlo);

    const headerAnimationText = (text) => {
        let rightText = "";
        switch (text) {
            case 0:
                rightText = data.datoCmsStronaGlowna.animowanyTekstPrawo1;
                break;
            case 1:
                rightText = data.datoCmsStronaGlowna.animowanyTekstPrawo2;
                break;
            case 2:
                rightText = data.datoCmsStronaGlowna.animowanyTekstPrawo3;
                break;
            case 3:
                rightText = data.datoCmsStronaGlowna.animowanyTekstPrawo4;
                break;
        }
        return rightText;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (index === 3) {
                setIndex(0);
            } else {
                setIndex(index + 1);
            }
        }, 2000);
        setText(headerAnimationText(index));
        return () => clearInterval(interval);
    });
    return (
        <StyledHeroSection>
            <StyledHeroImage image={image} alt="Przystanek na Leśnej" />
            <StyledHeaderText>
                <p>{data.datoCmsNaglowek.pierwszaLinia}</p>
                <p>{data.datoCmsNaglowek.drugaLinia}</p>
                    <StyledAnimationWrapper>
                        <p>
                            {data.datoCmsStronaGlowna.animowanyTekstLewo}{" "}
                            <AnimatePresence>
                                <motion.span
                                    key={text}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ ease: "easeInOut", duration: 0.75 }}
                                >
                                    {text}
                                </motion.span>
                            </AnimatePresence>
                        </p>
                    </StyledAnimationWrapper>
            </StyledHeaderText>
        </StyledHeroSection>
    );
};

export default HeroSection;
