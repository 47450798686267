/** @format */

import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledHeroSection = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 600px;
    overflow: hidden;
`;

export const StyledHeroImage = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
    obiect-fit: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.82);
    }
`;

export const StyledHeaderText = styled.div`
    color: #fff;
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    > p{
        font-size: 84px;
        font-family: Roboto;
        line-height: 1.4em;
        &:nth-child(2){
            padding-bottom: 18px;
        }
    }
    @media only screen and (max-width: 890px){
        > p{
            font-size: 62px;
        }
    }
    @media only screen and (max-width: 726px){
        > p{
            font-size: 44px;
        }
    }
    @media only screen and (max-width: 506px){
        > p{
            font-size: 36px;
        }
    }

    @media only screen and (max-width: 402px){
        > p{
            font-size: 30px;
        }
    }
    @media only screen and (max-width: 319px){
        > p{
            font-size: 22px;
        }
    }
`

export const StyledAnimationWrapper = styled.div`
    width: 500px;
    transform: translateX(70px);
    > p{
        font-size: 36px;
        font-family: Roboto;
        line-height: 1.6em;
        text-align: left;
        position: relative;
        &::after{
            content: '';
            width: 12%;
            height: 2px;
            border-radius: 10px;
            background: #FF4D00;
            left: -10px;
            bottom: -2px;
            position: absolute;
        }
        > span {
            color: #11CA59;
        }
    }

    @media only screen and (max-width: 726px){
        width: 450px;
        > p{
            font-size: 24px;
        }
    }
    @media only screen and (max-width: 506px){
        width: 300px;
        transform: translateX(20px);
        > p{
            font-size: 21px;
        }
    }
    @media only screen and (max-width: 402px){
        width: 250px;
        transform: translateX(10px);
        > p{
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 319px){
        width: auto;
        transform: translateX(0);
        > p{
            font-size: 16px;
        }
    }
`