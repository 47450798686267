/** @format */

import React from "react";
import GlobalLayout from "../components/layouts/GlobalLayout";
import HeroSection from "../components/HomePage/HeroSection";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

const IndexPage = () => {
    return (
        <GlobalLayout>
            <HelmetTemplate
                title="Przystanek na Leśnej"
                desc="Przystanek na leśnej, agroturystyka"
            />
            <HeroSection />
        </GlobalLayout>
    );
};

export default IndexPage;
